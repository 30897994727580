$(function () {
  if ($(".invoice.index").length < 1 && $(".invoice.search").length < 1) {
    return;
  }

  // 全選択
  $(".invoice_checkbox_all").on('change', function () {
    if ($('.invoice_checkbox_all:checked').length > 0) {
      $(".invoice_checkbox").prop("checked", true);
    } else {
      $(".invoice_checkbox").prop("checked", false);
    }
    mail_button_controll()
  });

  // チェックボックスがオンの時はメールボタンを活性化
  $(".invoice_checkbox").on('change', function () {
    mail_button_controll()
  });
  
  function mail_button_controll(){
    if ($('.invoice_checkbox:checked').length > 0) {
      $(".submit_email_button").prop("disabled", false);
    } else {
      $(".submit_email_button").prop("disabled", true);
    }
  }

})