import {
  //   disp_spinner_button,
  //   disp_flashing,
  disp_open_file
} from "../common/ui"
$(function () {
  if ($(".user.import_new").length < 1 && $(".user.import_edit").length < 1) {
    return;
  }
  disp_open_file("#user_csv", "#select_file_name")
})