export {
    disp_spinner_button,
    disp_flashing,
    disp_open_file,
    disp_open_file_image,
    disp_message,
    disp_date_hr_for_area,
    disp_date_hr,
    disp_add_form
}

/**
 * is_disabledがfalseのとき、ボタンにスピナーボタンを追加します。
 * @param {*} button_selector 
 * @param {*} is_disabled 
 * @param {*} submit_timeout ※※呼び出し元で戻り値を保持し、次の呼び出し時に引数として設定してください。初回呼び出し時の引数はnullにしてください。
 */
function disp_spinner_button(button_selector, is_disabled, submit_timeout) {

    var button = $(button_selector);
    var spinner = $(".spinner");

    if (spinner.length <= 0) {
        spinner = button.clone().hide();
        spinner.removeAttr("id").addClass("spinner").attr("disabled", "true");
        spinner.find("img").remove();
        var spinner_image = $("<span>").addClass("spinner-border spinner-border-sm");
        spinner.append(spinner_image);
        button.after(spinner);
    }

    // 非活性の場合
    if (is_disabled == true) {

        submit_timeout = setTimeout(function () {
            button.hide();
            spinner.show();
        }, 1000);


        // 活性の場合
    } else {
        if (submit_timeout != null) {
            clearTimeout(submit_timeout);
        }

        spinner.hide();
        button.show();
    }

    return submit_timeout;
}

/**
 * ボタンを点滅させます。
 * @param {*} inputfile_selector 
 * @param {*} button_selector 
 * @param {*} stop_selector 
 */
function disp_flashing(inputfile_selector, button_selector, stop_selector) {

    var button = $(button_selector);
    var interval = null;

    // 点滅終了
    if (interval != null) {
        clearInterval(interval);
    }

    $(inputfile_selector).change(function () {

        // 点滅終了
        if (interval != null) {
            clearInterval(interval);
        }

        //画像ファイル選択した場合
        if ($(this).get(0).files.length > 0) {

            // デザイン変更
            button.addClass("btn-danger");
            button.removeClass("btn-primary");

            // 点滅開始
            interval = setInterval(function () {
                button.fadeOut(500, function () {
                    $(this).fadeIn(500)
                });
            }, 1000);

            // 画像ファイルが存在しない場合
        } else {
            // デザイン変更
            button.addClass("btn-primary");
            button.removeClass("btn-danger");
        }
    });

    $(stop_selector).click(function () {
        // 点滅終了
        if (interval != null) {
            clearInterval(interval);
        }
        // デザイン変更
        button.addClass("btn-primary");
        button.removeClass("btn-danger");
    })

}

function disp_open_file(inputfile_selector, dispdiv_selector) {

    $(inputfile_selector).change(function () {

        // 選択中ファイル名削除
        $(dispdiv_selector).find("span").remove();
        var files = $(this).get(0).files;


        //画像ファイル選択した場合
        if (files.length > 0) {

            // 選択中ファイル名表示
            var filenameTemp = $("<span>")
            filenameTemp.addClass("badge badge-pill badge-info");

            for (var i = 0; i < files.length; i++) {

                var filename = filenameTemp.clone();

                filename.text(files[i].name);
                $(dispdiv_selector).append(filename);

            }
        }
    });
}

function disp_open_file_image(inputfile_selector, dispdiv_selector) {

    var current_file = $(dispdiv_selector).find(".image_preview").clone();

    $(inputfile_selector).change(function () {

        // 選択中ファイルプレビュー削除
        $(dispdiv_selector).find(".image_preview").remove();
        var files = $(this).get(0).files;

        //画像ファイル選択した場合
        if (files.length > 0) {

            // 選択中ファイル表示
            var preview_temp = $("<div>");
            var image_temp = $("<img>");

            preview_temp.addClass("image_preview");
            preview_temp.append(image_temp);

            for (var i = 0; i < files.length; i++) {

                var preview = preview_temp.clone();

                var image_url = window.URL.createObjectURL(files[i]);

                preview.find("img").attr("src", image_url);
                $(dispdiv_selector).append(preview);

            }
            // 画像ファイル選択されていない　かつ　登録済みの画像が存在する場合
        } else if (current_file.length > 0) {
            // 登録済み画像を表示
            $(dispdiv_selector).append(current_file.clone());
        }
    });
}

function disp_message(message, message_area) {

    var message_temp = $(".message.temp").clone().removeClass("temp");
    message_temp.find(".user").text(message.user.email);
    message_temp.find(".message_body").text(message.body);
    message_temp.find(".message_content").attr("data-message_id", message.id);
    message_temp.find(".date").text(message.create_hour).attr("data-year", message.create_year).attr("data-day", message.create_day).attr("data-hour", message.create_hour);

    if (message.image && message.image.url) {
        var img = $("<img>").prop("src", message.image.url);
        message_temp.find(".message_image").append(img);
    }
    if (message.updated) {
        var updated = message_temp.find(".updated")
        updated.text("(" + "編集済み " + message.updated_at + ")").removeClass("hidden");
    }

    var message_tags = message.message_tag
    message_tags.forEach(function (message_tag) {
        var tag_name = $("<span>").text(message_tag.tag.name).addClass("tag badge badge-pill badge-info").attr("data-tag_id", message_tag.tag.id);
        message_temp.find(".message_tags").append(tag_name);

    });


    if (message_area) {
        $(message_area).append(message_temp);
    }

    return message_temp;

}

function disp_date_hr_for_area(message_area) {

    message_area.find(".message").each(function () {
        disp_date_hr($(this));
    })

}

function disp_date_hr(message) {

    var prev_message = $(message).prevAll(".message").first();

    var prev_date = prev_message.find(".date");
    var prev_year = prev_date.data("year");
    var prev_day = prev_date.data("day");

    var date = $(message).find(".date");
    var year = date.data("year");
    var day = date.data("day");

    if (prev_year + prev_day != year + day) {
        if (!year || !day) {
            return;
        }
        var text = $("<span>").text(year + day);
        var date_hr = $("<div>").addClass("datehr").append("<hr>").append(text);

        $(message).before(date_hr);
    }

}

function disp_add_form(plus_button_selector, form_temp_selector, form_area) {
    var form_count = 0;

    $(plus_button_selector).click(function () {

        if (form_count > 9) {
            return;
        }

        form_count++;

        var form = $(form_temp_selector).clone();
        var input = form.find("input,select,textarea")

        var input_count = 0;

        input.each(function () {

            input_count++;
            var id = "input_" + form_count + "_" + input_count;
            $(this).attr("id", id).attr("name", id);

        });


        form.removeClass(form_temp_selector.replace(/\./, ''));

        $(form_area).append(form);
        form.removeClass("hidden");

    });
}
$(function () {
    $('.datepicker').datepicker({
        dateFormat: "yy/mm/dd"
    });

});