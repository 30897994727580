$(function () {
  // ドラッグ&ドロップエリアの取得
  var fileArea = document.getElementById('dropArea');

  // input[type=file]の取得
  var fileInput = document.getElementsByClassName('uploadFile')[0];

  if (!fileArea || !fileInput){
    return;
  }
  // ドラッグオーバー時の処理
  fileArea.addEventListener('dragover', function (e) {
    e.preventDefault();
    fileArea.classList.add('dragover');
  });

  // ドラッグアウト時の処理
  fileArea.addEventListener('dragleave', function (e) {
    e.preventDefault();
    fileArea.classList.remove('dragover');
  });

  // ドロップ時の処理
  fileArea.addEventListener('drop', function (e) {
    e.preventDefault();
    fileArea.classList.remove('dragover');

    // ドロップしたファイルの取得
    var files = e.dataTransfer.files;

    // 取得したファイルをinput[type=file]へ
    var dt = new DataTransfer();

    Array.from(fileInput.files).forEach(file => {
      dt.items.add(file);
    });
    
    Array.from(files).forEach(file => {
      var isExist = false
      Array.from(fileInput.files).forEach(existFile => {
        if (!isExist && existFile.name == file.name){
          isExist = true
        }
      });
      if (!isExist){
        dt.items.add(file);
      }
      
    });

    fileInput.files = dt.files;
    fileInput.dispatchEvent(new Event('change'))
  });

});